import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
    selector: 'dk-home',
    templateUrl: './home.component.html'
})  
export class HomeComponent implements OnInit {
    
    ngOnInit(): void {
        if ($("#rev_slider_2").length !== 0) {      
                 
            $("#rev_slider_2").show().revolution({
                sliderType: "standard",
                sliderLayout: "fullwidth",
                dottedOverlay: "none",
                delay: 9000,
                spinner: "spinner4",
                navigation: {
                    keyboardNavigation: "off",
                    keyboard_direction: "horizontal",
                    mouseScrollNavigation: "off",
                    onHoverStop: "off",
                    touch: {
                        touchenabled: "on",
                        swipe_threshold: 75,
                        swipe_min_touches: 1,
                        swipe_direction: "horizontal",
                        drag_block_vertical: false
                    },
                    arrows: {
                        enable: true,
                        style: 'metis',
                        tmp: '',
                        rtl: false,
                        hide_onleave: true,
                        hide_onmobile: true,
                        hide_under: 0,
                        hide_over: 9999,
                        hide_delay: 200,
                        hide_delay_mobile: 1200,
                        left: {
                            container: 'slider',
                            h_align: 'left',
                            v_align: 'center',
                            h_offset: 20,
                            v_offset: 0
                        },
                        right: {
                            container: 'slider',
                            h_align: 'right',
                            v_align: 'center',
                            h_offset: 20,
                            v_offset: 0
                        }
                    },
                    bullets: {
                        enable: false,
                    },
                },
                responsiveLevels: [1240, 1024, 767, 480],
                gridwidth: [1170, 1170, 767, 480],
                gridheight: [700, 700, 600, 600],
                lazyType: "none",
                shadow: 0,
                shuffle: "off",
                autoHeight: "off",
            });
        }
    }

}