import { Injectable } from '@angular/core';
import { ContactFormData } from './contact-form-data';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  postContactForm(contactFormData: ContactFormData) : Observable<any> {
    
    return this.http.post('https://www.dktuinen.be/api/mail', contactFormData);
    
  }

}
