<div class="rev_slider_wrapper fullscreen custom-controls">
    <div id="rev_slider_2" class="rev_slider fullscreenbanner" style="display: none;" data-version="5.4.5">
        <ul>

            <li data-transition="parallaxtoleft">

                <img src="img/slider/oprit1.jpg" alt="oprit1" class="rev-slidebg">

                <!-- start layer 1 -->
                <!--div class="tp-caption tp-resizeme alt-font font-weight-700 text-white" data-x="30" data-y="center" data-voffset="[-100,-120,-130,-140]" data-fontsize="[58,48,36,32]" data-lineheight="[60,52,40,36]" data-width="[none, none, none, 300]" data-whitespace="[nowrap, nowrap, nowrap, normal]" data-frames='[{
                            "delay":1500,
                            "speed":1400,
                            "frame":"0",
                            "from":"y:150px;opacity:0;",
                            "ease":"Power3.easeOut",
                            "to":"o:1;"
                            },{
                            "delay":"wait",
                            "speed":1000,
                            "frame":"999",
                            "to":"opacity:0;","ease":"Power3.easeOut"
                        }]' data-splitout="none">We Think Differently
                </div-->
                <!-- end layer 1 -->

                <!-- layer nr. 2 -->
                <!--div class="tp-caption tp-resizeme slider-text text-white" data-x="30" data-y="center" data-voffset="[-5,-10,-30,-15]" data-fontsize="[21,21,21,21]" data-lineheight="34" data-width="[600, 500, 400, 300]" data-whitespace="[nowrap, nowrap, nowrap, normal]" data-frames='[{
                            "delay":1700,
                            "speed":1400,
                            "frame":"0",
                            "from":"y:150px;opacity:0;",
                            "ease":"Power3.easeOut",
                            "to":"o:1;"
                            },{
                            "delay":"wait",
                            "speed":1000,
                            "frame":"999",
                            "to":"opacity:0;","ease":"Power3.easeOut"
                        }]'>
                    <p class="white-space">Trusted and professional business consultant to fulfill your dreams with latest technology putting on project.</p>
                </div-->
                <!-- END LAYER 2 -->

                <!-- layer nr. 3 -->
                <!--div class="tp-caption tp-resizeme" data-x="30" data-y="center" data-voffset="[90,110,90,115]" data-lineheight="55" data-hoffset="0" data-frames='[{
                                "delay":1900,
                                "speed":1400,
                                "frame":"0",
                                "from":"y:150px;opacity:0;",
                                "ease":"Power3.easeOut",
                                "to":"o:1;"
                                },{
                                "delay":"wait",
                                "speed":1000,
                                "frame":"999",
                                "to":"opacity:0;","ease":"Power3.easeOut"
                            }]'><a href='#' class='butn theme'><span>View Project</span></a>
                </div-->
                <!-- layer nr. 3 -->

            </li>

            <li data-transition="parallaxtoright">

                <img src="img/slider/buxus1.jpg" alt="buxus1" class="rev-slidebg">

                <!-- start layer 1 -->
                <!--div class="tp-caption tp-resizeme alt-font font-weight-700 text-white text-right" data-x="['right','right','right','right']" data-y="center" data-hoffset="[30,30,30,30]" data-voffset="[-100,-120,-130,-140]" data-fontsize="[58,48,36,32]" data-lineheight="[60,52,40,36]" data-width="[none, none, none, 300]" data-whitespace="[nowrap, nowrap, nowrap, normal]" data-frames='[{
                            "delay":1500,
                            "speed":1400,
                            "frame":"0",
                            "from":"y:150px;opacity:0;",
                            "ease":"Power3.easeOut",
                            "to":"o:1;"
                            },{
                            "delay":"wait",
                            "speed":1000,
                            "frame":"999",
                            "to":"opacity:0;","ease":"Power3.easeOut"
                        }]' data-splitout="none">We Build Solution 
                </div-->
                <!-- end layer 1 -->

                <!-- layer nr. 2 -->
                <!--div class="tp-caption tp-resizeme slider-text text-white text-right" data-x="['right','right','right','right']" data-y="center" data-voffset="[-5,-10,-30,-15]" data-fontsize="[21,21,21,21]" data-hoffset="[30,30,30,30]" data-lineheight="34" data-width="[600, 500, 400, 300]" data-whitespace="[nowrap, nowrap, nowrap, normal]" data-frames='[{
                            "delay":1700,
                            "speed":1400,
                            "frame":"0",
                            "from":"y:150px;opacity:0;",
                            "ease":"Power3.easeOut",
                            "to":"o:1;"
                            },{
                            "delay":"wait",
                            "speed":1000,
                            "frame":"999",
                            "to":"opacity:0;","ease":"Power3.easeOut"
                        }]'>
                    <p class="white-space">Trusted and professional business consultant to fulfill your dreams with latest technology putting on project.</p>
                </div-->
                <!-- END LAYER 2 -->

                <!-- layer nr. 3 -->
                <!--div class="tp-caption tp-resizeme text-right" data-x="['right','right','right','right']" data-y="center" data-voffset="[90,110,90,115]" data-lineheight="55" data-hoffset="[30,30,30,30]" data-frames='[{
                                "delay":1900,
                                "speed":1400,
                                "frame":"0",
                                "from":"y:150px;opacity:0;",
                                "ease":"Power3.easeOut",
                                "to":"o:1;"
                                },{
                                "delay":"wait",
                                "speed":1000,
                                "frame":"999",
                                "to":"opacity:0;","ease":"Power3.easeOut"
                            }]'><a href='#' class='butn theme'><span>View Project</span></a>
                </div-->
                <!-- layer nr. 3 -->

            </li>

            <li data-transition="parallaxtoleft">

                <img src="img/slider/buxus2.jpg" alt="slide1" class="rev-slidebg">

                <!-- start layer 1 -->
                <!--div class="tp-caption tp-resizeme alt-font font-weight-700 text-white" data-x="30" data-y="center" data-voffset="[-100,-120,-130,-140]" data-fontsize="[58,48,36,32]" data-lineheight="[60,52,40,36]" data-width="[none, none, none, 300]" data-whitespace="[nowrap, nowrap, nowrap, normal]" data-frames='[{
                            "delay":1500,
                            "speed":1400,
                            "frame":"0",
                            "from":"y:150px;opacity:0;",
                            "ease":"Power3.easeOut",
                            "to":"o:1;"
                            },{
                            "delay":"wait",
                            "speed":1000,
                            "frame":"999",
                            "to":"opacity:0;","ease":"Power3.easeOut"
                        }]' data-splitout="none">Be Inspired By Best
                </div-->
                <!-- end layer 1 -->

                <!-- layer nr. 2 -->
                <!--div class="tp-caption tp-resizeme slider-text text-white" data-x="30" data-y="center" data-voffset="[-5,-10,-30,-15]" data-fontsize="[21,21,21,21]" data-lineheight="34" data-width="[600, 500, 400, 300]" data-whitespace="[nowrap, nowrap, nowrap, normal]" data-frames='[{
                            "delay":1700,
                            "speed":1400,
                            "frame":"0",
                            "from":"y:150px;opacity:0;",
                            "ease":"Power3.easeOut",
                            "to":"o:1;"
                            },{
                            "delay":"wait",
                            "speed":1000,
                            "frame":"999",
                            "to":"opacity:0;","ease":"Power3.easeOut"
                        }]'>
                    <p class="white-space">Trusted and professional business consultant to fulfill your dreams with latest technology putting on project.</p>
                </div-->
                <!-- END LAYER 2 -->

                <!-- layer nr. 3 -->
                <!--div class="tp-caption tp-resizeme" data-x="30" data-y="center" data-voffset="[90,110,90,115]" data-lineheight="55" data-hoffset="0" data-frames='[{
                                "delay":1900,
                                "speed":1400,
                                "frame":"0",
                                "from":"y:150px;opacity:0;",
                                "ease":"Power3.easeOut",
                                "to":"o:1;"
                                },{
                                "delay":"wait",
                                "speed":1000,
                                "frame":"999",
                                "to":"opacity:0;","ease":"Power3.easeOut"
                            }]'><a href='#' class='butn theme'><span>View Project</span></a>
                </div-->
                <!-- layer nr. 3 -->

            </li>

        </ul>
    </div>
</div>

<section>
    <div class="container">
        <div class="section-heading">
            <h3>De visie van DK tuinen</h3>
            <p>
                <!--small description-->
            </p>
        </div>
        <div class="row">
            <div class="col"></div>
            <div class="col-7">
                <span>Tegenwoordig is de tuin een verlengstuk van het huis geworden.<br />
                    Het is een plek waar we willen relaxen en van willen genieten.<br />
                    Door goed te kijken naar de beschikbare ruimte en deze zo efficiënt mogelijk te gebruiken,<br />
                    kan van elke tuin een plek worden gemaakt die helemaal past bij de huidige levensstijl.
                </span>
            </div>
            <div class="col"></div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="section-heading">
            <h3>Tuinaanleg & onderhoud</h3>
            <p>
                <!--small description-->
            </p>
        </div>
        <div class="row">
            <div class="col"></div>
            <div class="col-7">
                <span>DK tuinen heeft al heel wat ervaring in het ontwerpen,<br />
                    aanleggen en onderhouden van tuinen. Dit zowel voor particulieren als bedrijven.<br />
                    Gaat het om een eenmalig onderhoud of een maandelijkse verzorging van uw tuin?<br />
                    Wat uw wensen ook zijn, wij verzorgen uw tuin tot in de puntjes!</span>
            </div>
            <div class="col"></div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="section-heading">
            <h3>Opritten & terrassen</h3>
            <p>
                <!--small description-->
            </p>
        </div>
        <div class="row">
            <div class="col"></div>
            <div class="col-7">
                <span>DK tuinen zorgt niet alleen voor een groene toets,<br />
                    maar ook voor de aanleg van uw oprit of terras.<br />
                    Hiervoor kan u kiezen uit strakke natuursteen, beton- of kleiklinkers, kasseien, blauwe steen,
                    grind, …<br />
                    Wij doen ook grond- en graafwerken.<br />
                    Dit zowel voor niveauverschillen, drainage als voor het leggen van funderingen.
                </span>
            </div>
            <div class="col"></div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="section-heading">
            <h3>Afsluitingen</h3>
            <p>
                <!--small description-->
            </p>
        </div>
        <div class="row">
            <div class="col"></div>
            <div class="col-7">
                <span>Een afsluiting of omheining is al lang niet meer louter functioneel.<br />
                    Naast privacy en bescherming voor familie en huisdier<br />
                    zorgt het steeds meer en meer voor dé persoonlijke toets aan uw tuin.<br />
                    Bij de keuze van het type afsluiting wordt dan ook rekening gehouden met de stijl van uw tuin en
                    uw huis.
                    <br /><br />Bij DK tuinen kan u terecht voor de levering en plaatsing van verschillende types
                    afsluiting:
                    <br />• draadafsluiting
                    <br />• houten panelen
                    <br />• kastanje afsluiting
                    <br />• steenkorven
                    <br />• …
                </span>
            </div>
            <div class="col"></div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="section-heading">
            <h3>Vellen van bomen</h3>
            <p>
                <!--small description-->
            </p>
        </div>
        <div class="row">
            <div class="col"></div>
            <div class="col-7">
                <span>Wilt u enkele bomen laten snoeien, maar is het werk te gevaarlijk om het zelf te doen?<br />
                    Of moet er een boom geveld worden?<br /><br />
                    Vertrouw dan op onze expertise voor deze zware klus.<br />
                    Al het tuinafval wordt steeds netjes weggevoerd, zodat u zich verder geen zorgen hoeft te maken.
                </span>
            </div>
            <div class="col"></div>
        </div>
    </div>
</section>