import { Component, OnInit } from '@angular/core';
import { ContactFormData } from './contact-form-data';
import { NgForm } from '@angular/forms';
import { DataService } from './data.service';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
    selector: 'contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

    contactFormData : ContactFormData = {
        name: null,
        phone: null,
        email: null,
        message: null
    };

    postError = false;
    mailSent = false;
   
    constructor(private dataService: DataService) {}

    ngOnInit(): void {

        // sections background image from data background
        var pageSection = $(".bg-img");
        pageSection.each(function(indx) {

            if ($(this).attr("data-background")) {
                $(this).css("background-image", "url(" + $(this).data("background") + ")");
            }
        });
    }

    onHttpError(errorResponse: any) {
        console.log('error: ', errorResponse);
        this.postError = true;
    }

    onSubmit(form: NgForm) {
        console.log('in onSubmit: ', form.valid);
        if (form.valid)
        {
            console.log('captcha: ',  this.contactFormData.captcha);

            this.postError = false;
            this.mailSent = false;

            this.dataService.postContactForm(this.contactFormData).subscribe(
                result => {
                    this.mailSent = true;
                    console.log('success');
                },
                error => this.onHttpError(error)            
            );
        }
    }
}