import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title: string = 'dktuinen';
  
  ngOnInit() {

    $(".scroll-to-top").on('click', function(event) {
      event.preventDefault();
      $("html, body").animate({
          scrollTop: 0
      }, 600);
    });
  }
}
