<!-- start page title section -->
<section class="page-title-section2 bg-img cover-background" data-overlay-dark="7" data-background="img/contact/contact-us.jpg">
    <div class="container">

        <div class="row">
            <div class="col-md-12">
                <h1>Contact</h1>
            </div>
            <div class="col-md-12">
                <ul>
                    <li><a [routerLink]="['/home']">Home</a></li>
                    <li><a href="javascript:void(0)">Contact</a></li>
                </ul>
            </div>
        </div>

    </div>
</section>
<!-- end page title section -->

<!-- start contact section -->
<section>
    <div class="container">
        <div class="row">
            <!-- start contact form -->
            <div class="col-lg-6 sm-margin-30px-bottom xs-margin-20px-bottom">
                <div class="section-heading left">
                    <h4>Heeft u een vraag?</h4>
                </div>
                <div class="contact-form-box margin-30px-top">
                    <form #form="ngForm" (ngSubmit)="onSubmit(form)">
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" name="name" placeholder="Uw naam:" class="form-control" [(ngModel)]="contactFormData.name" required #nameField="ngModel">
                            </div>
                            <div class="col-md-6">
                                <input type="text" name="phone" placeholder="Telefoon:" class="form-control" [(ngModel)]="contactFormData.phone" required #phoneField="ngModel">
                            </div>
                            <div class="col-md-6">
                                <input type="text" name="email" placeholder="Email:" class="form-control" [(ngModel)]="contactFormData.email" required #emailField="ngModel" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" >
                            </div>
                            <div class="col-md-12">
                                <textarea name="message" rows="5" placeholder="Bericht:" class="form-control" [(ngModel)]="contactFormData.message" required #messageField="ngModel"></textarea>
                            </div>
                            <div class="col-md-12">
                                <re-captcha [(ngModel)]="contactFormData.captcha" name="captcha" required siteKey="6LcEY8kZAAAAAFt9nXdVPurAjctOJOmCtg_LpOC6" #captchaControl="ngModel"></re-captcha>
                            </div>
                            <div class="col-md-12 margin-20px-top">
                                <button type="submit" class="butn"><span>Verzenden</span></button>
                            </div>
                            <div class="col-md-12 margin-20px-top error-summary" [hidden]="!form.submitted || (nameField.valid && (phoneField.valid || emailField.valid) && messageField.valid && captchaControl.valid)">
                                <strong>Fout!</strong> Gelieve het formulier correct in te vullen!
                                <ul>
                                    <li [hidden]="nameField.valid">Naam is verplicht.</li>
                                    <li [hidden]="phoneField.valid || emailField.valid">Telefoon of email is verplicht.</li>
                                    <li [hidden]="emailField.pristine || emailField.valid">Email formaat onjuist.</li>
                                    <li [hidden]="messageField.valid">Bericht is verplicht.</li>
                                    <li [hidden]="captchaControl.valid">Captcha is niet correct.</li>
                                </ul>    
                            </div>
                            <div class="col-md-12 margin-20px-top error-summary" [hidden]="!postError">
                                <strong>Fout!</strong> Uw aanvraag kon niet verwerkt worden. Probeer het later opnieuw.
                            </div>
                            <div class="col-md-12 margin-20px-top message-summary" [hidden]="!mailSent">
                                Uw aanvraag werd verwerkt!
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- end contact form  -->

            <!-- start contact detail -->
            <div class="col-lg-6">
                <div class="contact-info-box padding-30px-left sm-no-padding">
                    <div class="row">
                        <div class="col-12">
                            <div class="contact-info-section no-padding-top margin-10px-top">

                                <h4>Contacteer ons...</h4>
                                <p>
                                    U kan dit invulformulier gebruiken om ons te contacteren.
                                </p>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="contact-info-section">

                                <h4>Contact gegevens...</h4>
                                <ul class="list-style-1 no-margin-bottom">
                                    <li>
                                        <p><i class="fas fa-map-marker-alt text-center"></i> <strong>Adres: </strong>Vollickstraat 17, 1785 Brussegem</p>
                                    </li>
                                    <li>
                                        <p><i class="fas fa-phone text-center"></i> <strong>Telefoon: </strong>0477 86 47 60</p>
                                    </li>
                                    <li>
                                        <p><i class="far fa-envelope text-center"></i> <strong>Email: </strong> <a href="javascript:void(0)">info@dktuinen.be</a></p>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end contact detail -->
        </div>
    </div>
</section>
<!-- end contact section -->
