<!-- start main-wrapper section -->
<div class="main-wrapper">

    <!-- start header section -->
    <header>
        <div id="top-bar">
            <div class="container">
                <div class="row">
                    <div class="col-md-9 col-xs-12">
                        <div class="top-bar-info">
                            <ul>
                                <li><i class="fas fa-mobile-alt"></i>0477 86 47 60</li>
                                <li><i class="fas fa-envelope"></i>info@dktuinen.be</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3 xs-display-none">
                        <ul class="top-social-icon">
                            <li><a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fab fa-google-plus-g"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="navbar-default">

            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-12">
                        <div class="menu_area alt-font">
                            <nav class="navbar navbar-expand-lg navbar-light no-padding">

                                <div class="navbar-header navbar-header-custom">
                                    <!-- Start Logo -->
                                    <a [routerLink]="['/home']" class="navbar-brand logodefault">
                                        <img id="logo" src="img/logos/logo.png" alt="logo">
                                    </a>
                                    <!-- End Logo -->
                                </div>

                                <div class="navbar-toggler"></div>

                                <!-- Menu Area -->
                                <ul class="navbar-nav ml-auto" id="nav">
                                    <li>
                                        <a [routerLink]="['/home']">Home</a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['/contact']">Contact</a>
                                    </li>
                                </ul>

                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- end header section -->

    <router-outlet></router-outlet>

    <!-- start footer section -->
    <footer class="no-padding-top">

        <div class="footer-top-bar margin-50px-bottom xs-margin-30px-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3">
                        <div
                            class="padding-30px-all sm-padding-25px-tb sm-no-padding-lr border-right border-color-light-white sm-no-border-right sm-border-bottom">
                            <span
                                class="d-inline-block font-size36 line-height-30 sm-font-size32 sm-line-height-30 text-light-gray vertical-align-top width-40px"><i
                                    class="fas fa-cut vertical-align-top"></i></span>
                            <div class="d-inline-block vertical-align-top padding-10px-left width-75">
                                <h5 class="no-margin text-white text-uppercase font-size15">Tuinaanleg & onderhoud</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div
                            class="padding-30px-all sm-padding-25px-tb sm-no-padding-lr border-right border-color-light-white sm-no-border-right sm-border-bottom">
                            <span
                                class="d-inline-block font-size36 line-height-30 sm-font-size32 sm-line-height-30 text-light-gray vertical-align-top width-40px"><i
                                    class="fas fa-road vertical-align-top"></i></span>
                            <div class="d-inline-block vertical-align-top padding-10px-left width-75">
                                <h5 class="no-margin text-white text-uppercase font-size15">Opritten & terrassen</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div
                            class="padding-30px-all sm-padding-25px-tb sm-no-padding-lr border-right border-color-light-white sm-no-border-right sm-border-bottom">
                            <span
                                class="d-inline-block font-size36 line-height-30 sm-font-size32 sm-line-height-30 text-light-gray vertical-align-top width-40px"><i
                                    class="fas fa-snowplow vertical-align-top"></i></span>
                            <div class="d-inline-block vertical-align-top padding-10px-left width-75">
                                <h5 class="no-margin text-white text-uppercase font-size15">Afsluitingen</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="padding-30px-all sm-padding-25px-tb sm-no-padding-lr">
                            <span
                                class="d-inline-block font-size36 line-height-30 sm-font-size32 sm-line-height-30 text-light-gray vertical-align-top width-40px"><i
                                    class="fas fa-tree vertical-align-top"></i></span>
                            <div class="d-inline-block vertical-align-top padding-10px-left width-75">
                                <h5 class="no-margin text-white text-uppercase font-size15">Vellen van bomen</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">

                <div class="col-lg-8 col-md-6 sm-margin-30px-bottom">
                    <img alt="footer-logo" src="img/logos/logo-footer.png">
                    <p class="margin-25px-top margin-20px-bottom">DK tuinen heeft reeds geruime jaren ervaring in het
                        leveren van diensten. Buiten onze expertise staan wij ook garant voor een vakkundige afhandeling
                        van uw tuin project. Voor elk budget vinden wij een gepaste oplossing.</p>
                    <ul class="footer-list">
                        <li>
                            <span class="d-inline-block vertical-align-top font-size18"><i
                                    class="fas fa-map-marker-alt text-theme-color"></i></span>
                            <span class="d-inline-block width-85 vertical-align-top padding-10px-left">Vollickstraat 17, 1785 Brussegem</span>
                        </li>
                        <li>
                            <span class="d-inline-block vertical-align-top font-size18">
                                <i class="fas fa-mobile-alt text-theme-color"></i>
                            </span>
                            <span class="d-inline-block width-85 vertical-align-top padding-10px-left">0477 86 47 60</span>
                        </li>
                        <li>
                            <span class="d-inline-block vertical-align-top font-size18">
                                <i class="far fa-envelope text-theme-color"></i>
                            </span>
                            <span class="d-inline-block width-85 vertical-align-top padding-10px-left">info@dktuinen.be</span>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-4 col-md-6 sm-margin-30px-bottom">
                    <h3 class="text-white">Links</h3>
                    <div class="row">
                        <div class="col-md-6 no-padding-right xs-padding-15px-right">
                            <ul class="footer-list xs-margin-5px-bottom">
                                <li><a href="javascript:void(0)">Nieuws (later)</a></li>
                            </ul>
                        </div>
                        <div class="col-md-6 no-padding-right xs-padding-15px-right">
                            <ul class="footer-list">
                                <li><a href="javascript:void(0)">Portfolio (later)</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-bar">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 text-left xs-text-center xs-margin-5px-bottom">
                        <div class="footer-social-icons small">
                            <ul>
                                <li><a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="javascript:void(0)"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="javascript:void(0)"><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a href="javascript:void(0)"><i class="fab fa-youtube"></i></a></li>
                                <li><a href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 text-right xs-text-center">
                        <p class="xs-margin-5px-top xs-font-size13">&copy; 2020</p>
                    </div>
                </div>
            </div>
        </div>

    </footer>
    <!-- end footer section -->

</div>
<!-- end main-wrapper section -->

<!-- start scroll to top -->
<a href="javascript:void(0)" class="scroll-to-top"><i class="fas fa-angle-up" aria-hidden="true"></i></a>
<!-- end scroll to top -->
